import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import './Offers.scss'
class OffersComparator extends React.Component {

    constructor(props) {
        super(props);
    }

    computePrice(offer) {

        let tmp = "Sur devis"
        if (offer.priceType === "price") {
            tmp = <span>{offer.price}<b>€</b></span>
        } else if (offer.priceType === "hourlyRate") {
            tmp = <span>{offer.price}<b>€</b>&nbsp;&nbsp;/ heure</span>
        } else if (offer.priceType === "subscription") {
            tmp = <span>{offer.price}<b>€</b>&nbsp;&nbsp;/ an</span>
        }

        return <span className="price">{tmp}</span>
    }

    renderOffers(offers) {
        return offers.map((offer, i) => {
            let price = this.computePrice(offer)
            return  <div key={`${offer.type}-${i}`} className="pricing-option">
                        <i className="material-icons">eco</i>
                        <h1>{offer.name}</h1>
                        <hr/>
                        <p>{offer.description}</p>
                        <hr/>
                        <div className="price">
                            <div className="front">
                                <span className="price">{price}</span>
                            </div>
                            <div className="back">
                                <Link to={`/votre-projet?siteType=${offer.type}`} className="button">Nous contacter</Link>
                            </div>
                        </div>
                    </div>
        })
    }

    render() {
        return (
            <div className="pricing-table">
                {this.renderOffers(this.props.offers)}
            </div>
        );
    }

}
OffersComparator.propTypes = {
    offers: PropTypes.object.isRequired
};

export default OffersComparator