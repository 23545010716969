import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";

class ScrollToTop extends React.Component {

    componentDidUpdate(prevProps) {

        if (this.props.location.pathname !== prevProps.location.pathname) {

            const width = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;

            window.scrollTo(0, width < this.props.smallDeviceMaxWidth
                ? this.props.smallDeviceScrollPosition
                : this.props.scrollPosition);
        }
    }

    render() {
        return this.props.children;
    }
}

ScrollToTop.propTypes = {
    smallDeviceMaxWidth: PropTypes.number,
    scrollPosition: PropTypes.number,
    smallDeviceScrollPosition: PropTypes.number
}

ScrollToTop.defaultProps = {
    smallDeviceMaxWidth: 737,
    scrollPosition: 0,
    smallDeviceScrollPosition: 0

}

export default withRouter(ScrollToTop);