import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from "react-router-dom";
import {isMobile, toggleClassInBody} from "./lib/js/menuUtils";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            darken: false,
            isNavBarFixed: false

        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    componentDidUpdate(prevProps) {

        let location = this.props.location.pathname;
        let scrollTop = window.scrollY;
        if (scrollTop === 0 && location === "/" && this.state.isNavbarFixed === true) {
            this.setState({isNavbarFixed: false, darken: false})
        } else if (location !== "/" && !this.state.darken) {
            this.setState({darken: true})
        }
    }

    handleScroll() {

        let location = this.props.location.pathname;
        let headerDarkenPosition = isMobile() ? 608 : 690
        let navBarFixedPosition = isMobile() ? 133 : 156

        if (location === "/") {
            let scrollTop = window.scrollY;
            if (scrollTop > headerDarkenPosition && scrollTop > navBarFixedPosition - 3) {
                this.setState({darken: true, isNavBarFixed: true})
            } else if(scrollTop <= navBarFixedPosition) {
                this.setState({isNavBarFixed: false})
            } else if (scrollTop > navBarFixedPosition - 3) {
                this.setState({darken: false, isNavBarFixed: true})
            } else if (scrollTop < headerDarkenPosition && scrollTop >= navBarFixedPosition) {
                this.setState({darken: false, isNavBarFixed: false})
            }
        }
    }

    onMenuClicked() {

        const width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        if (width < 737) {
            toggleClassInBody('with--sidebar')
        }
    }

    render() {

        let headerClass = this.state.darken ? "darken" : ""
        let location = this.props.location.pathname
        let headerColor = this.state.darken ? "#433F3D" : "#fff"
        let textColor = this.state.darken ? "#fff" : "#433F3D"
        return this.state.isNavBarFixed || location !== "/" ? (
                    <header className={`App-header ${headerClass}`}>
                        <div id="App-menu-icon" onClick={this.onMenuClicked}>
                        </div>
                        <div className="App-brand">
                            <Link to={"/"}>
                                <div className="App-name">Soon Web Agency</div>
                            </Link>
                        </div>
                        <nav className="App-nav">
                            {/*<ul className="App-menu">*/}
                            {/*    <li><Link to="/notre-offre" onClick={this.onMenuClicked}>Notre offre</Link></li>*/}
                            {/*    <li><Link to="/contact" onClick={this.onMenuClicked}>Notre engament</Link></li>*/}
                            {/*</ul>*/}
                            <div className="App-menu__right">
                                <Link className="btn red" to="/votre-projet" onClick={this.onMenuClicked.bind(this)}>
                                    <span>Proposer un projet</span>
                                </Link>
                                <Link className="btn" to="/login" onClick={this.onMenuClicked.bind(this)}>
                                    <span>Se connecter</span>
                                </Link>
                            </div>
                        </nav>

                    </header>
                ) : null
    }

}
Header.propTypes = {
    scrollPosition: PropTypes.number.isRequired,
};
export default withRouter(Header)