import React from 'react'

import './OffersList.css'
class OfferList extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        return <div id="generic_price_table">
            <section>
                <div className="offer-list-container">
                    <div className="offer-list-item">

                        <div className="generic_content clearfix">

                            <div className="generic_head_price clearfix">

                                <div className="generic_head_content clearfix">

                                    <div className="head_bg"></div>
                                    <div className="head">
                                        <span>Éco</span>
                                    </div>
                                </div>
                                <div className="generic_price_tag clearfix">
                                    <span className="price">
                                        <span className="currency">39</span>
                                        <span className="sign">€</span>
                                        <span className="cent"></span>
                                        <span className="month">/AN</span>
                                    </span>
                                </div>
                            </div>
                            <div className="generic_feature_list">
                                <ul>
                                    <li><span>1GB</span> d'espace disque</li>
                                    <li><span>10Mo</span> de bas de donnée</li>
                                    <li><span>1</span> email</li>
                                    <li><span>1</span> nom de domaine </li>
                                    <li><span>24/7</span> Support</li>
                                </ul>
                            </div>
                            <div className="generic_price_btn clearfix">
                                <a className="" href="">Sign up</a>
                            </div>
                        </div>
                    </div>

                    <div className="offer-list-item">
                        <div className="generic_content active clearfix">
                            <div className="generic_head_price clearfix">
                                <div className="generic_head_content clearfix">
                                    <div className="head_bg"></div>
                                    <div className="head">
                                        <span>Perso</span>
                                    </div>
                                </div>

                                <div className="generic_price_tag clearfix">
                                    <span className="price">
                                        <span className="currency">99</span>
                                        <span className="sign">€</span>
                                        <span className="month">/AN</span>
                                    </span>
                                </div>
                            </div>
                            <div className="generic_feature_list">
                                <ul>
                                    <li><span>20GB</span> d'espace disque</li>
                                    <li><span>200Mo</span> de basse de donnée</li>
                                    <li><span>10</span> emails</li>
                                    <li><span>1</span> nom de domaine</li>
                                    <li><span>24/7</span> Support</li>
                                </ul>
                            </div>
                            <div className="generic_price_btn clearfix">
                                <a className="" href="">Sign up</a>
                            </div>
                        </div>
                    </div>
                    <div className="offer-list-item">
                        <div className="generic_content clearfix">
                            <div className="generic_head_price clearfix">

                                <div className="generic_head_content clearfix">

                                    <div className="head_bg"></div>
                                    <div className="head">
                                        <span>Pro</span>
                                    </div>
                                </div>
                                <div className="generic_price_tag clearfix">
                                    <span className="price">
                                        <span className="currency">199</span>
                                        <span className="sign">€</span>
                                        <span className="cent"></span>
                                        <span className="month">/AN</span>
                                    </span>
                                </div>

                            </div>
                            <div className="generic_feature_list">
                                <ul>
                                    <li><span>50GB</span> d'espace disque</li>
                                    <li><span>600Mo</span> de basse de donnée</li>
                                    <li><span>100</span> emails</li>
                                    <li><span>1</span> nom de domaine</li>
                                    <li><span>24/7</span> Support</li>
                                </ul>
                            </div>
                            <div className="generic_price_btn clearfix">
                                <a className="" href="">Sign up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }

}

export default OfferList