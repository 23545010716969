import React from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom'
import {isMobile, toggleClassInBody} from "./lib/js/menuUtils";
import './App.css';
import './styles/inputs.css'
import HomePage from "./pages/HomePage";
import ContactUs from "./pages/ContactUs";
import LoginPage from "./pages/LoginPage";

import Quotation from "./pages/Quotation";
import Offer from "./pages/Offer";
import Header from "./Header";

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavbarFixed: false,
            darken: this.props.location.path !== "/"
        }

        this.content = React.createRef()
        this.onMenuClicked = this.onMenuClicked.bind(this)
    }

    componentDidUpdate(prevProps) {

        let topPosition = window.innerWidth < 737 ? 0 : 150
        this.content.current.scrollTo(0, topPosition);
    }

    onMenuClicked() {

        const width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        if (width < 737) {
            toggleClassInBody('with--sidebar')
        }
    }

    render() {

        return (
            <div className="App">
                <Header />
                <div ref={this.content} className="App-content">
                    <Switch>
                        <Route exact path='/' component={HomePage}/>
                        <Route path='/contact' component={ContactUs}/>
                        <Route path="/login" component={LoginPage} />
                        <Route path="/notre-offre" component={Offer} />
                        <Route path="/votre-projet" component={Quotation} />
                    </Switch>
                </div>




                {/*          <footer className="App-footer">
                        <div className="Footer-app-name">Soon Web Agency</div>
                        <div className="Footer-app-hook">Créateur de site internet à votre image</div>
                    </footer>*/}

                <div className="site-cache" onClick={this.onMenuClicked.bind(this)}/>
            </div>
        );

    }
}

export default withRouter(App);
