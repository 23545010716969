import React from 'react';
import SelectComponent from "@muskacirca/react-select/lib/index";
import {validEmail, validPhone} from '../lib/js/validationUtils'

import './Quotations.css'
import './Forms.css'
import TextArea from "../components/TextArea";

class Quotation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldsInError: [],
            error: null,
            submitted: false
        }

        this.siteType = React.createRef();
        this.description = React.createRef();
        this.numberOfPage = React.createRef();
        this.name = React.createRef();
        this.surname = React.createRef();
        this.email = React.createRef();
        this.phone = React.createRef();
        this.hostingNeeded = React.createRef();
        this.hostingMethod = React.createRef();
        this.hostname = React.createRef();


    }

    onSubmit(e) {

        e.preventDefault()

        console.log("this.state.hostingMethod : " + JSON.stringify(this.state.hostingMethod))
        let siteType = this.state.siteType
        let description = this.state.description
        let numberOfPage = this.state.numberOfPage
        let name = this.name.current.value;
        let surname = this.surname.current.value;
        let phone = this.phone.current.value;
        let email = this.email.current.value;
        let hostingMethod = this.state.hostingMethod;
        let hostname = this.hostname.current.value;

        if (email && !validEmail(email)) {
            this.setState({error: "Le format de votre email n'est pas correct"})
        } else if (phone && !validPhone(phone)) {
            this.setState({error: "Le format de votre téléphone n'est pas correct"})
        } else if (!name || !email || !siteType || !surname || !numberOfPage || !description) {
            this.setState({error: "Tous les champs sauf le téléphone sont obligatoire"})
        } else {

            fetch(`${process.env.REACT_APP_SOON_AGENCY_HOST}/mailer/send`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-API-KEY': process.env.REACT_APP_SOON_AGENCY_API_KEY
                },
                mode: 'cors',
                method: "POST",
                body: JSON.stringify({
                    env: process.env.NODE_ENV,
                    lastname: name,
                    firstname: surname,
                    recipient: process.env.REACT_APP_CONTACT_RECIPIENT,
                    phone: phone,
                    contactEmail: email,
                    subject: "[Soon Agency] Nouvelle demande de devis",
                    extraData: [{
                        name: "Type de site",
                        value: siteType
                    }, {
                        name: "Nombre de page",
                        value: numberOfPage
                    }, {
                        name: "Description",
                        value: description
                    }, {
                        name: "Hébergement",
                        value: hostingMethod
                    }, {
                        name: "Nom de domaine",
                        value: hostname
                    }]
                })})
                .then(response => {
                    this.setState({submitted: true, message: "Votre demande à bien été envoyée. Nous vous contacterons très prochainement."})
                })
                .catch(error => {
                    this.setState({error: "Erreur technique, contactez nous par téléphone"})
                })
        }
    }

    onFieldChange(name, value) {
        let stateTmp = Object.assign({}, this.state)
        stateTmp[name] = value
        this.setState(stateTmp)
    }

    render() {
        return (
            <div id="quotation-page" className="page-content">

                { this.state.message
                    ? (<div className="message-container">{this.state.message}</div>)
                    : (<div className="page form-container">
                        <div className="form-hook">
                            <h1>Il est temps de parler de votre projet</h1>
                            <span>Êtes vous prêt à vous révéler au monde ? L'Internet n'attend plus que vous !</span><br />
                            <span>Afin de comprendre au mieux votre besoin, j'aurais besoin de quelques renseignement sur votre projet.</span>
                        </div>
                        <form onSubmit={this.onSubmit.bind(this)}>
                            <div className="field-container">
                                <label htmlFor="login">Type de site</label>
                                <SelectComponent
                                    name="siteType"
                                    placeholder="Sélectionnez le type de site que vous désirez"
                                    ref={this.siteType}
                                    onChange={this.onFieldChange.bind(this)}
                                    choices={["Site single page", "Site personnalisé", "Service de maintenance"]}>
                                </SelectComponent>
                            </div>
                            <div className="field-container">
                                <label htmlFor="numberOfPage">Nombre de page souhaité</label>
                                <SelectComponent
                                    name="numberOfPage"
                                    placeholder="Sélectionnez un nombre de page"
                                    ref={this.numberOfPage}
                                    onChange={this.onFieldChange.bind(this)}
                                    choices={["moins de 5 pages", "5 à 10 pages", "10 à 20 pages", "20 pages et plus"]}>
                                </SelectComponent>
                            </div>
                            <div className="field-container">
                                <label htmlFor="hostname">Avez-vous déjà un nom de domaine ?</label>
                                <input ref={this.hostname}
                                       className="field"
                                       placeholder="ex : monsupersite.fr"
                                       name="hostname"
                                       type="text" />
                            </div>
                            <div className="field-container">
                                <label htmlFor="description">Description brève de votre projet</label>
                                <TextArea ref={this.description} name="description"
                                          placeholder=""
                                          onChange={this.onFieldChange.bind(this)} />
                            </div>
                            <div className="field-container">
                                <label htmlFor="hostingMethod">Concernant l'hébergement</label>
                                <SelectComponent
                                    name="hostingMethod"
                                    placeholder="Sélectionnez une méthode d'hébergement"
                                    ref={this.hostingMethod}
                                    onChange={this.onFieldChange.bind(this)}
                                    choices={[{name: "inHouseHosting", desc: "Je souhaite bénificier d'un hébergement web"},
                                        {name: "selfHosting", desc: "J'ai mon propre hébergement"},
                                        {name: "notSure", desc: "Je ne sais pas"}]}>
                                </SelectComponent>
                            </div>
                            <div className="field-container">
                                <label htmlFor="name">Nom</label>
                                <input ref={this.name}
                                       className="field"
                                       name="name"
                                       type="text"/>
                            </div>
                            <div className="field-container">
                                <label htmlFor="surname">Prénom</label>
                                <input ref={this.surname}
                                       className="field"
                                       name="surname"
                                       type="text"/>
                            </div>
                            <div className="field-container">
                                <label htmlFor="email">Email</label>
                                <input ref={this.email}
                                       className="field"
                                       type="email"/>
                            </div>
                            <div className="field-container">
                                <label htmlFor="phone">Téléphone (facultatif)</label>
                                <input ref={this.phone}
                                       className="field"
                                       name="phone"
                                       pattern="(0|\+33|0033)[1-9][0-9]{8}"
                                       type="tel"/>
                            </div>
                            <div className="field-container">
                                {this.state.error ? <div className="error-block">{this.state.error}</div> : null}
                                <input className="btn wide green" type="submit" value="Submit" />
                            </div>
                        </form>
                    </div>)
                }
            </div>
        )
    }
}

export default Quotation