import React from 'react';
import PropTypes from 'prop-types';

class TextArea extends React.Component {

    constructor(props) {
        super(props);
        this.description = React.createRef()
    }

    onChange(e) {
        e.preventDefault()
        this.props.onChange(this.props.name, this.description.current.value)
    }

    render() {
        return (
            <textarea ref={this.description} name={this.props.name}
                      placeholder={this.props.placeholder} cols="30" rows="5"
                      onChange={this.onChange.bind(this)} />
        );
    }
}
TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default TextArea