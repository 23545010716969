import React from 'react';
import ParallaxContainer from "../components/utils/Parallax";
import LightBulbBackground from "../img/bulb-background.jpg";
import LightBulbBackgroundMobile from "../img/bulb-background-mobile.jpg";
import CodeBackground from "../img/code-1.jpg";
import ConceptBackground from "../img/concept-background.jpg";
import ResponsiveIcon from "../img/responsive-icons.png";
import GrassBackground1 from "../img/grass-background-1.jpg";
import HotAirBalloonBackground from "../img/hot-air-balloon.jpg";
import HotAirBalloonBackgroundMobile from "../img/hot-air-balloon-mobile.jpg";
import {Link} from 'react-router-dom'

import './HomePage.css'
import {isMobile} from "../lib/js/menuUtils";
import OffersComparator from "../components/offers/OffersComparator";
import SERVICES from '../data/services.json'
class HomePage extends React.Component {

    constructor(props) {
        super(props);
    }

    computeScrollTextClass() {
        let scrollPosition = window.scrollY;
        return scrollPosition > 120 ? "fade" : "pop"
    }

    render() {

        let fadeEffect = this.computeScrollTextClass()
        let parallaxBackgroundHeight = isMobile() ? "300px" : "550px"

        return (
            <div style={{height: "100%"}}>

                <ParallaxContainer backgroundImage={isMobile() ? LightBulbBackgroundMobile : LightBulbBackground}>
                    <div className="parallax-title" style={{top: "20%"}}>Soon Web Agency</div>
                    <div className="parallax-subtitle" style={{top: isMobile() ? "30%" : "28%"}}>Créateur de site internet à votre image</div>
                    <div className={`parallax-text ${fadeEffect}`} style={{
                        display: "flex",
                        flexDirection: "row",
                        width: isMobile() ? "100%": "100%",
                        top: "90%"}}>

                        <div className="arrow bounce">
                            <a className="fa fa-arrow-down fa-2x" href="#"></a>
                        </div>
                        <div>Scrollez vers le bas pour découvrir notre offre ou <Link to="/login">connectez-vous</Link></div>
                    </div>
                </ParallaxContainer>

                <div className="static-element">
                    <h1>Notre offre</h1>
                    <div>
                        <div>Du simple site vitrine au site de vente en ligne, nous réalisons des sites web personnalisé selon vos besoin.</div>
                    </div>
                    <OffersComparator offers={SERVICES}/>
                    {/*<div><Link to="/notre-offre">En savoir plus</Link></div>*/}
                </div>

                <ParallaxContainer backgroundImage={CodeBackground} minHeight={parallaxBackgroundHeight} />

                <div className="static-element">
                    <div>
                        <h1>Comprendre votre idée, notre priorité</h1>
                        <div>Parce que la préparation est la clé du succès, nous sommes à votre écoute lors de toute la phase de définition de votre projet</div>
                    </div>
                </div>

                <ParallaxContainer backgroundImage={ConceptBackground} minHeight={parallaxBackgroundHeight} />

                <div className="static-element">
                    <div className="flex in-row">
                        <div className={isMobile() ? "full-width" : "one-half" }>
                            <h1>Des sites internet responsive et responsable</h1>
                            <ul>
                                <li>Afin que vos visiteurs profitent pleinement de l'expérience, nos sites internet s'adaptent à tout les supports</li>
                                <li>Respect des normes de sécurité en vigueur</li>
                                <li>Sites optimisé pour le référencement Google</li>
                                <li>Hébergé en France</li>
                                <li>5 heures de maintenance offertes</li>
                            </ul>
                        </div>
                        <div className="mobile-hide one-half">
                            <img style={{width: "100%"}} src={ResponsiveIcon} alt={"Dessin d'un smartphone, d'une tablette et d'un ordinateur"}/>
                        </div>
                    </div>
                </div>

                <ParallaxContainer backgroundImage={GrassBackground1} minHeight={parallaxBackgroundHeight} />

                <div className="static-element">
                    <h1>Vous gardez le contrôle</h1>
                    <ul>
                        <li>Durant la phase de développement, un site de démo est accessible pour que vous suiviez les évolutions au fur et à mesure</li>
                        <li>A la fin du projet vous recevez votre site et le code qui va avec</li>
                        <li>Tout est mis en place pour que les futures évolutions se passent le mieux possible</li>
                    </ul>
                </div>

                <ParallaxContainer backgroundImage={isMobile() ? HotAirBalloonBackgroundMobile : HotAirBalloonBackground}>
                    <div className="parallax-title"><Link to="/votre-projet">Contactez-nous</Link></div>
                    <div className="parallax-subtitle" style={{top: isMobile() ? "30%" : "28%"}}>et démarrez l'aventure du web maintenant</div>

                </ParallaxContainer>
            </div>
        )
    }

}

export default HomePage