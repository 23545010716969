import React from 'react';
import PropTypes from 'prop-types'
import {isMobile} from "../../lib/js/menuUtils";

class ParallaxContainer extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        let minHeight = this.props.minHeight ? this.props.minHeight : "100%"
        let mobileImgClass = isMobile() && this.props.minHeight === "100%" ? "in-height" : ""

        return isMobile() && this.props.minHeight !== "100%"
                ? (<div className={`parallax-background`}>
                    <img className={`img-responsive`} src={this.props.backgroundImage} />
                    {this.props.children}
                   </div>)
                : ( <div className="parallax-background" style={{
                        backgroundImage: `url(${this.props.backgroundImage})`,
                        minHeight: minHeight }}>
                        {this.props.children}
                    </div>)

    }
}

ParallaxContainer.propTypes = {
    minHeight: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string.isRequired
}

ParallaxContainer.defaultProps = {
    minHeight: "100%"
}
export default ParallaxContainer
