import React from 'react';

import './LoginPage.css'
import './Forms.css'
class LoginPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    onSubmit(e) {
        e.preventDefault()
        this.setState({error: "Mauvais login / mot de passe"})
    }

    render() {
        return (
            <div id="login-page" className="page-content">

                <div className="form-container">
                    <div className="form-hook">
                        <h1>Veuillez vous connecter</h1>
                        <span>Ceci est un accès pour les clients de la Soon Web Agency.</span>
                    </div>
                    <form onSubmit={this.onSubmit.bind(this)}>
                        {this.state.error ? <div className="error-block">{this.state.error}</div> : null}
                        <div className="field-container">
                            <label htmlFor="login">Identifiant</label>
                            <input className="field" name="login"/>
                        </div>
                        <div className="field-container">
                            <label htmlFor="password">Mot de passe</label>
                            <input className="field" name="password"/>
                        </div>
                        <input className="btn wide green" type="submit" value="S'identifier" />
                    </form>
                </div>
            </div>
        )

    }

}

export default LoginPage