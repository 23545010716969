import React from 'react';
import OffersComparator from "../components/offers/OffersComparator";
import SERVICES from '../data/services.json'
import HOSTING from '../data/hosting.json'

import './Offer.scss'
import OfferList from "../components/offers/OfferList";
class Offer extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="offer-page" className="">
                <h1>Découvrez nos offres</h1>
                <div className="offer-item">
                    <h2>Création de site web</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique faucibus semper. Nulla facilisi. Quisque lacinia, purus eget maximus posuere, augue mauris pellentesque est, eget vehicula augue lorem accumsan odio. Nam tempor mi ac risus imperdiet, vitae lacinia nisi efficitur. Fusce fermentum consequat velit, in.</p>
                    <OffersComparator offers={SERVICES}/>
                </div>
                <div className="offer-item light-greyed">
                    <h2>Hébergement</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique faucibus semper. Nulla facilisi. Quisque lacinia, purus eget maximus posuere, augue mauris pellentesque est, eget vehicula augue lorem accumsan odio. Nam tempor mi ac risus imperdiet, vitae lacinia nisi efficitur. Fusce fermentum consequat velit, in.</p>
                    <OfferList />
                </div>
            </div>
        )
    }
}

export default Offer